import { useState } from "react";
import { Button, Col, Input, Row, Form } from "antd";
import classes from "./ForgotPassword.module.css";
import { Link, useNavigate } from "react-router-dom";
import { sendOtp } from "../../services/AuthService";
import { toast } from "react-toastify";
import RegisterFlowLayout from "../Layout/RegisterFlowLayout";
import { emailSchema } from "../../schemas";

const initialValues = {
  email: "",
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false);

  const submitHandler = async (values) => {
    try {
      setFormLoading(true);
      const res = await sendOtp(values);
      if (res && res.status === 200) {
        toast.success(res.message);
        navigate("/public/verifyOtp", { state: values });
      } else {
        throw new Error(res.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <RegisterFlowLayout title={"Forgot Password"}>
      <Row>
        <Col span={8} />
        <Col span={8}>
          <Form onFinish={submitHandler} initialValues={initialValues}>
            <label htmlFor="email">Email</label>
            <Form.Item name="email" rules={emailSchema}>
              <Input
                id="email"
                placeholder="Email"
                className={classes["custom-input"]}
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={classes["submit-btn"]}
              loading={formLoading}
            >
              Submit
            </Button>
          </Form>
        </Col>
        <Col span={8} />
      </Row>
      <Row>
        <Col span={24}>
          <p className={classes.terms}>
            By continuing you agree to our{" "}
            <Link to="/public/terms">Terms of Usage</Link> and{" "}
            <Link to="/public/privacy">Privacy Policy</Link>
          </p>
        </Col>
        <Col span={24}>
          <p className={classes.terms}>
            Don’t have an account? <Link to="/public/signup">Sign Up</Link>
          </p>
        </Col>
      </Row>
    </RegisterFlowLayout>
  );
};

export default ForgotPassword;
