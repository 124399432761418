import { Button, Col, Row } from "antd";
import classes from "./JoinNowbadge.module.css";
import EmpowerBusiness from "./../../images/LandingPageCMP/EmpowerBusiness.png";
import { useNavigate } from "react-router-dom";

const JoinNowBadge = () => {
  const navigate = useNavigate();
  //const empowerBusinessContent = "";

  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={24} className={classes.maindiv}>
        <div className="d-flex align-content-center justify-content-center">
          <img src={EmpowerBusiness} className={classes.EmpowerBusinessImg} />

          <div className={classes.EmpowerBusinessDiv}>
            <h4 className={classes.heading}>
              Empower and Accelerate your Private Brands with PriBr
            </h4>
            {/* <p className={classes.content}>{empowerBusinessContent}</p> */}
          </div>
        </div>
        <Button
          className={classes.joinNowBtn}
          onClick={() => navigate("/public/signup")}
        >
          Join Now
        </Button>
      </Col>
    </Row>
  );
};

export default JoinNowBadge;
