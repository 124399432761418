import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Tooltip, Upload } from "antd";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import FileValidationConstant from "../../../constant/FileValidationConstant";
import { AddEditProductSchema, EditProductSchema } from "../../../schemas";
import { GetAllCategory } from "../../../services/GeneralService";
import {
  addProduct,
  deleteProductFile,
} from "../../../services/MyProductService";
import { getDepartments } from "../../../store/slice/generalData_slice";
import classes from "./AddEditProduct.module.css";

const initialFileList = [];
const AddEditProduct = (props) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [logoError, setLogoError] = useState({ message: "", exists: false });
  const [fileList, setFileList] = useState([]);

  const auth = useSelector((state) => state?.auth);
  const departments = useSelector((state) => state?.generalData?.departments);
  const dispatch = useDispatch();

  //for initial get departments and category data start
  const [departmentData, setDepartmentData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [categoryDataMap, setCategoryDataMap] = useState([]);
  const [allCategoryWithId, setAllCategoryWithId] = useState([]);
  const [formTitleObj, setFormTitleObj] = useState({
    title: "Add Product",
    btnTxt: "Add Product",
  });

  const GetSetDepartMentAndCategory = async () => {
    if (!departments?.length) {
      dispatch(getDepartments());
    } else {
      const response = departments?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      setDepartmentData(response);
    }

    try {
      const res = await GetAllCategory({ pagination: false });
      const resTempArr = res?.data?.content || [];
      const catArrTemp = [];
      for (let index = 0; index < resTempArr?.length; index++) {
        const catDep = {
          category: resTempArr[index]?.name,
          department: resTempArr[index]?.department?.name,
        };
        catArrTemp[index] = catDep;
      }

      setCategoryDataMap(() => [...catArrTemp]);

      if (resTempArr) {
        setAllCategoryWithId(resTempArr);
        setCategoryData(resTempArr);
      } else {
        setCategoryData([]);
      }
    } catch {
      setCategoryData([]);
    }
  };

  useEffect(() => {
    GetSetDepartMentAndCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //for initiall get departments and category data end

  let initialValues = {
    productName: "",
    department: null,
    category: null,
    productDescription: "",
    minOrderQuantity: "",
    casePackDimension: "",
    casePackWeight: "",
    avgUnitSize: "",
    avgUnitWeight: "",
  };
  const product = props?.product;
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: product || initialValues,
    validationSchema: product ? EditProductSchema : AddEditProductSchema,
    onSubmit: (values, action) => {
      submitHandler(values, action);
    },
  });

  let userPlan = parseInt(localStorage.getItem("subscription"), 10) ?? 1;
  let maxImageLimit = 0;
  switch (userPlan) {
    case 1:
      maxImageLimit = 2;
      break;
    case 2:
      maxImageLimit = 3;
      break;
    case 3:
      maxImageLimit = 10;
      break;
    default:
      maxImageLimit = 2;
      break;
  }
  useEffect(() => {
    if (product) {
      //get department from id start

      const departmentGet = departmentData?.filter(
        (item) => item?.value == product?.departmentId,
      );
      const categoryGet = allCategoryWithId?.filter(
        (item) => item?.id == product?.categoryId,
      );

      if (categoryGet[0]?.name != values?.category) {
        setFieldValue("department", departmentGet[0]?.value);
        setFieldValue("productName", product?.name);
        setFieldValue("productDescription", product?.description);
        setFieldValue("minOrderQuantity", product?.minimumOrderQuantity);
        setFieldValue("casePackDimension", product?.caseDimension);
        setFieldValue("casePackWeight", product?.caseWeight);
        setFieldValue("avgUnitSize", product?.avgUnitSize);
        setFieldValue("avgUnitWeight", product?.avgUnitWeight);
        setFieldValue("category", categoryGet[0]?.id);
        departmentChangeHandler(departmentGet[0]?.value, categoryGet[0]?.id);
      }

      initialFileList.length = 0;
      if (product?.mediaURLs != null) {
        for (let index = 0; index < product?.mediaURLs.length; index++) {
          const element = {
            uid: `${index}`,
            name: product?.mediaURLs[index],
            status: "done",
            url: product?.mediaURLs[index],
            size: FileValidationConstant?.ALLOWED_FILE_SIZE_ADD_PRODUCT - 200, //not not show validation on aws files
          };
          initialFileList[index] = element;
        }
      }

      if (imageUrl != product?.mediaURLs) {
        if (product?.mediaURLs != null) {
          setImageUrl(product?.mediaURLs[0]);
          setFileList(initialFileList); //for setting images initially
        } else {
          setImageUrl(product?.mediaURLs);
          setFileList(initialFileList); //for setting images initially
        }
      }

      setFormTitleObj({
        title: "Edit Product",
        btnTxt: "Save",
      });
    } else {
      resetForm();
      setFormTitleObj({
        title: "Add Product",
        btnTxt: "Add Product",
      });
      setImageUrl("");
      setFileList([]);
    }
  }, [product, departmentData, allCategoryWithId]);

  let [uploadDocsFileList, SetUploadDocsFileList] = useState([
    {
      url: "",
      status: "done",
      uid: "-1",
    },
  ]);

  const submitHandler = (values, action) => {
    const deletedFiles = initialFileList
      ?.filter(
        (item) =>
          !fileList?.some((secondItem) => secondItem?.name === item?.name),
      )
      ?.map((item) => item?.name);

    //for create deleted arry end

    if (logoError.exists) {
      return;
    }
    let fileArrObjTemp = [];
    for (let index = 0; index < uploadDocsFileList?.length; index++) {
      fileArrObjTemp[index] = uploadDocsFileList[index]?.originFileObj;
    }
    let formData = new FormData();

    fileArrObjTemp.forEach((file) => {
      if (file !== undefined) {
        formData?.append(`product_image`, file);
      }
    });

    deletedFiles?.forEach((name) => {
      formData?.append(`deletedFiles`, name);
    });

    values?.productName && formData?.append("name", values?.productName);
    values?.department && formData?.append("departmentId", values?.department);
    values?.category && formData?.append("categoryId", values?.category);
    values?.productDescription &&
      formData?.append("description", values?.productDescription);
    values?.minOrderQuantity &&
      formData?.append("minimumOrderQuantity", values?.minOrderQuantity);
    values?.casePackDimension &&
      formData?.append("caseDimension", values?.casePackDimension);
    values?.casePackWeight &&
      formData?.append("caseWeight", values?.casePackWeight);
    values?.avgUnitSize && formData?.append("avgUnitSize", values?.avgUnitSize);
    values?.avgUnitWeight &&
      formData?.append("avgUnitWeight", values?.avgUnitWeight);
    auth?.id && formData?.append("userId", auth?.id);

    if (formTitleObj?.title == "Edit Product") {
      product?.id && formData?.append("id", product?.id);
    }
    //create object for api call end

    setLoading(true);
    addProduct(formData)
      ?.then((res) => {
        if (res && res?.data) {
          toast?.success(res?.message);
          action?.resetForm();
          SetUploadDocsFileList([]);
          setFileList([]);
          setImageUrl("");
          setLoading(false);

          props?.handleOk();
        } else {
          toast?.error(res?.message);
          setLoading(false);
          return;
        }
      })
      ?.catch((err) => {
        toast?.error(err);
        setLoading(false);
        return;
      });
  };
  useEffect(() => {
    if (!props?.product) {
      setFileList([]);
    }

    resetForm();
  }, [props?.tempCount]);

  const productNameInputHasError = touched?.productName && errors?.productName;
  const departmentInputHasError = touched?.department && errors?.department;
  const categoryInputHasError = touched?.category && errors?.category;
  const productDescriptionInputHasError =
    touched?.productDescription && errors?.productDescription;
  const minOrderQuantityInputHasError =
    touched?.minOrderQuantity && errors?.minOrderQuantity;
  const casePackDimensionInputHasError =
    touched?.casePackDimension && errors?.casePackDimension;
  const casePackWeightInputHasError =
    touched?.casePackWeight && errors?.casePackWeight;
  const avgUnitSizeInputHasError = touched?.avgUnitSize && errors?.avgUnitSize;
  const avgUnitWeightInputHasError =
    touched?.avgUnitWeight && errors?.avgUnitWeight;

  const changeUploadDocsFile = (file) => {
    const allowedFileTypeLogo =
      FileValidationConstant?.ALLOWED_FILE_TYPE_ADD_PRODUCT;
    const allowedFileSizeLogo =
      FileValidationConstant?.ALLOWED_FILE_SIZE_ADD_PRODUCT;

    var isValidFileType = true;
    var isValidFileSize = true;
    for (let index = 0; index < file.fileList.length; index++) {
      const element = file.fileList[index];
      const fileExtension = `.${element.name.split(".").pop().toLowerCase()}`;
      isValidFileType = allowedFileTypeLogo.includes(fileExtension);
      isValidFileSize = element.size <= allowedFileSizeLogo;
      file.fileList[index].status = "done"; //for changing the file status
    }

    if (!isValidFileType) {
      setLogoError({
        message: `Only ${allowedFileTypeLogo?.join(", ")} files are allowed?.`,
        exists: true,
      });
      SetUploadDocsFileList([]);
      setImageUrl("");
      return;
    }

    if (!isValidFileSize) {
      setLogoError({
        message: `File size must be within ${
          allowedFileSizeLogo / (1024 * 1024)
        }MB?.`,
        exists: true,
      });
      SetUploadDocsFileList([]);
      setImageUrl("");
      return;
    }
    setLogoError({ message: ``, exists: false });

    const updatedFileList = file?.fileList?.filter(
      (file) => file?.status !== "removed",
    );
    console.log("filelist: ", fileList);
    setFileList(updatedFileList);

    //file show end

    if (file.fileList.length <= maxImageLimit) {
      // let fileArr = [file.fileList[file.fileList.length - 1]];
      let fileArr = file.fileList;
      // fileArr[0].status = "done";
      SetUploadDocsFileList(fileArr);
    } else {
      setLogoError({
        message: `You can only insert upto ${maxImageLimit} images`,
        exists: true,
      });

      SetUploadDocsFileList([]);
    }
  };

  const uploadButtonDocs = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <UploadOutlined style={{ fontSize: "27px" }} />
      )}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const departmentChangeHandler = (value, categoryVal = "") => {
    setFieldValue("department", value);
    setFieldValue("category", categoryVal);

    const tempCategory = categoryData
      ?.filter((item) => item?.department?.id === value) // Filter based on the condition
      ?.map((item) => ({ value: item?.id, label: item?.name })); // Create a new array with specific properties

    setCategoryDataMap(tempCategory);
  };

  const previewFile = async (file) => {
    if (file?.type?.includes("image")) {
      // You can use URL.createObjectURL to get a temporary URL for the preview
      const imageUrl = URL.createObjectURL(file.originFileObj);
      window.open(imageUrl, "_blank");
    } else {
      window?.open(file?.url, "_blank");
    }
    return ""; // Return an empty string for non-image files
  };

  const deleteFileHandler = (path) => {
    if (product?.id) {
      console.log("productid: ", product.id);
      deleteProductFile({ id: product.id, fieldToClear: path })
        .then(() => {
          toast.success("File Deleted");
        })
        .catch((err) => {
          toast.error(`Error deleting file: ${err.message}`);
        });
    } else {
      toast.error("Product ID is missing");
    }
  };

  return (
    <>
      <h2>{formTitleObj?.title}</h2>
      <form onSubmit={handleSubmit}>
        <Row>
          <p className="m-0" style={{ marginBottom: "8px" }}>
            Only .png, .jpg, .jpeg file types are supported?.
          </p>
          <Upload
            className="avatar-uploader"
            listType="picture-card"
            onChange={changeUploadDocsFile}
            headers={{ Authorization: "authorization-text" }}
            name="fileUpload"
            onPreview={previewFile}
            fileList={fileList}
            accept=".png, .jpg, .jpeg"
            onRemove={(file) => {
              deleteFileHandler(file.url);
            }}
          >
            {uploadButtonDocs}
          </Upload>
          {logoError?.exists ? (
            <p className={classes["error-text"]}>{logoError?.message}</p>
          ) : null}
        </Row>

        <Row gutter={12}>
          <Col xs={24} md={24} lg={24} xl={24} xxl={24}>
            <div className={classes["form-label"]}>
              <label htmlFor="productName">Product Name</label>
            </div>
            <div>
              <Input
                type="text"
                id="productName"
                className={classes["form-input"]}
                value={values?.productName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Product Name"
              />
              {productNameInputHasError ? (
                <p className={classes["error-text"]}>{errors?.productName}</p>
              ) : null}
            </div>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
            <div className={classes["form-label"]}>
              <label htmlFor="department">Department</label>
            </div>
            <div>
              <Select
                id="department"
                className={classes["form-select"]}
                showSearch
                placeholder="Select department"
                optionFilterProp="children"
                value={values?.department}
                onBlur={handleBlur}
                onChange={(value) => departmentChangeHandler(value)}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                options={departmentData}
              />
              {departmentInputHasError ? (
                <p className={classes["error-text"]}>{errors?.department}</p>
              ) : null}
            </div>
          </Col>

          <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
            <div className={classes["form-label"]}>
              <label htmlFor="category">Category</label>
            </div>
            <div>
              <Select
                id="category"
                className={classes["form-select"]}
                showSearch
                placeholder="Select category"
                optionFilterProp="children"
                value={values?.category}
                onBlur={handleBlur}
                onChange={(value) => setFieldValue("category", value)}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
                options={categoryDataMap}
                disabled={!values?.department}
              />
              {categoryInputHasError ? (
                <p className={classes["error-text"]}>{errors?.category}</p>
              ) : null}
            </div>
          </Col>
        </Row>

        <Row gutter={12}>
          <Tooltip
            title={
              userPlan === 1
                ? "Please upgrade to a premium plan to unlock this field"
                : ""
            }
          >
            <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
              <div className={classes["form-label"]}>
                <label htmlFor="productDescription">Product Description</label>
              </div>
              <div>
                <Input
                  type="text"
                  id="productDescription"
                  className={classes["form-input"]}
                  value={values?.productDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={userPlan === 1}
                  placeholder="Enter Product Description"
                />
                {productDescriptionInputHasError ? (
                  <p className={classes["error-text"]}>
                    {errors?.productDescription}
                  </p>
                ) : null}
              </div>
            </Col>
          </Tooltip>

          <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
            <div className={classes["form-label"]}>
              <label htmlFor="minOrderQuantity">Minimum Order Quantity</label>
            </div>
            <div>
              <Input
                type="text"
                id="minOrderQuantity"
                className={classes["form-input"]}
                value={values?.minOrderQuantity}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter Minimum Quantity for Order"
              />
              {minOrderQuantityInputHasError ? (
                <p className={classes["error-text"]}>
                  {errors?.minOrderQuantity}
                </p>
              ) : null}
            </div>
          </Col>
        </Row>

        <Tooltip
          title={
            userPlan === 1 || userPlan === 2
              ? "Please upgrade to a premium plan to unlock this field"
              : ""
          }
        >
          <Row gutter={12}>
            <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
              <div className={classes["form-label"]}>
                <label htmlFor="casePackDimension">Case Pack dimensions</label>
              </div>
              <div>
                <Input
                  type="text"
                  id="casePackDimension"
                  className={classes["form-input"]}
                  value={values?.casePackDimension}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Case Pack dimensions"
                  disabled={userPlan === 1 || userPlan === 2}
                />
                {casePackDimensionInputHasError ? (
                  <p className={classes["error-text"]}>
                    {errors?.casePackDimension}
                  </p>
                ) : null}
              </div>
            </Col>
            <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
              <div className={classes["form-label"]}>
                <label htmlFor="casePackWeight">Case pack weight</label>
              </div>
              <div>
                <Input
                  type="text"
                  id="casePackWeight"
                  className={classes["form-input"]}
                  value={values?.casePackWeight}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Case pack weight"
                  disabled={userPlan === 1 || userPlan === 2}
                />
                {casePackWeightInputHasError ? (
                  <p className={classes["error-text"]}>
                    {errors?.casePackWeight}
                  </p>
                ) : null}
              </div>
            </Col>
          </Row>
        </Tooltip>
        <Tooltip
          title={
            userPlan === 1 || userPlan === 2
              ? "Please upgrade to a premium plan to unlock this field"
              : ""
          }
        >
          <Row gutter={12}>
            <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
              <div className={classes["form-label"]}>
                <label htmlFor="avgUnitSize">Average Unit Size</label>
              </div>
              <div>
                <Input
                  type="text"
                  id="avgUnitSize"
                  className={classes["form-input"]}
                  value={values?.avgUnitSize}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Average Unit Size"
                  disabled={userPlan === 1 || userPlan === 2}
                />
                {avgUnitSizeInputHasError ? (
                  <p className={classes["error-text"]}>{errors?.avgUnitSize}</p>
                ) : null}
              </div>
            </Col>

            <Col xs={24} md={24} lg={12} xl={12} xxl={12}>
              <div className={classes["form-label"]}>
                <label htmlFor="avgUnitWeight">Average Unit Weight</label>
              </div>
              <div>
                <Input
                  type="text"
                  id="avgUnitWeight"
                  className={classes["form-input"]}
                  value={values?.avgUnitWeight}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Enter Average Unit Weight"
                  disabled={userPlan === 1 || userPlan === 2}
                />
                {avgUnitWeightInputHasError ? (
                  <p className={classes["error-text"]}>
                    {errors?.avgUnitWeight}
                  </p>
                ) : null}
              </div>
            </Col>
          </Row>
        </Tooltip>
      </form>

      <Button
        type="primary"
        htmlType="submit"
        onClick={handleSubmit}
        className={classes?.addProductBtn}
        loading={loading}
      >
        {formTitleObj?.btnTxt}
      </Button>
    </>
  );
};

export default AddEditProduct;
