import { Button, Form, Input, Segmented } from "antd";
import classes from "./SupplierSignup.module.css";
import { registerUser } from "../../services/AuthService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  companyNameSchema,
  confirmPasswordSchema,
  emailSchema,
  passwordSchema,
  userNameSchema,
} from "../../schemas";

const radioOptions = [
  {
    label: <div className={classes["tab-options"]}>Supplier</div>,
    value: "SUPPLIER",
  },
  {
    label: <div className={classes["tab-options"]}>Retailer</div>,
    value: "RETAILER",
  },
];

const initialValues = {
  company_name: "",
  email: "",
  user_name: "",
  user_type: "",
  password: "",
  confirm_password: "",
};

const SupplierSignup = () => {
  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false);
  const submitHandler = async (values) => {
    try {
      setFormLoading(true);
      const res = await registerUser(values);
      if (res?.data) {
        toast.success(res.message);
        navigate("/public/signin");
      } else {
        throw new Error(res.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <Form onFinish={submitHandler} initialValues={initialValues}>
      <label htmlFor="user_type">Select User Type </label>
      <Form.Item
        name="user_type"
        rules={[{ required: true, message: "Please Select User Type" }]}
        className={"tab-container"}
      >
        <Segmented
          block
          size="large"
          defaultValue={null}
          options={radioOptions}
        />
      </Form.Item>

      <label htmlFor="user_name">Name</label>
      <Form.Item name="user_name" rules={userNameSchema}>
        <Input
          id="user_name"
          placeholder="Enter Name"
          className={classes["custom-input"]}
        />
      </Form.Item>

      <label htmlFor="company_name">Company Name</label>
      <Form.Item name="company_name" rules={companyNameSchema}>
        <Input
          id="company_name"
          placeholder="Enter Company Name"
          className={classes["custom-input"]}
        />
      </Form.Item>

      <label htmlFor="email">Email</label>
      <Form.Item name="email" rules={emailSchema}>
        <Input
          id="email"
          placeholder="Enter Email"
          className={classes["custom-input"]}
        />
      </Form.Item>

      <label htmlFor="password">Password</label>
      <Form.Item name="password" rules={passwordSchema}>
        <Input.Password
          id="password"
          type="password"
          placeholder="Enter Password"
          className={classes["custom-input"]}
        />
      </Form.Item>

      <label htmlFor="confirm_password">Confirm Password</label>
      <Form.Item
        name="confirm_password"
        dependencies={["password"]}
        rules={confirmPasswordSchema}
      >
        <Input.Password
          id="confirm_password"
          type="password"
          placeholder="Enter confirm Password"
          className={classes["custom-input"]}
        />
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        className={classes["submit-btn"]}
        loading={formLoading}
      >
        Submit
      </Button>
    </Form>
  );
};

export default SupplierSignup;
