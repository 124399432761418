import React from "react";
import { Button, Progress } from "antd";
import classes from "./ProfileCompleteNoticeDash.module.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProfileCompleteNoticeDash = () => {
  const percentage = useSelector((state) => state.percentage.value);
  const navigate = useNavigate();

  const completeNowClickHandler = () => {
    navigate("/app/createprofile");
  };

  return (
    <div className={classes["bar"]}>
      <div className={classes["percentage"]}>
        <div className={classes["flex-center"]}>
          <Progress
            type="circle"
            percent={percentage}
            size={"small"}
            width={40}
            height={40}
            strokeWidth={12}
            strokeColor="#fff"
          />
          <div className={classes["percentage-text"]}>
            <h3 className={classes["text"]}>{percentage}%</h3>
            <h5 className={classes["text"]}>Profile Complete</h5>
          </div>
        </div>

        <Button
          className={classes["complete-btn"]}
          onClick={completeNowClickHandler}
        >
          Complete Now
        </Button>
      </div>
    </div>
  );
};

export default ProfileCompleteNoticeDash;
