import ProfileCompleteNotice from "../Dashboard/ProfileCompleteNotice";
import BannerCarasoul from "./Carasoul/BannerCarasoul";
import MainCarasoul from "./Carasoul/MainCarasoul";
import classes from "./LandingPageCmpUser.module.css";

import { Typography } from "antd";
import BlogCarasoul from "./Carasoul/BlogCarasoul";
import { useEffect, useState } from "react";
import { getBannerByLocation } from "../../services/LandingPageService";
import { useSelector } from "react-redux";

const { Title } = Typography;

const LandingPageCmpUser = () => {
  //API Integration start
  const [eventData, setEventData] = useState({});
  const percentage = useSelector((state) => state.percentage.value);

  const showbadge = percentage != 100;
  const getData = async () => {
    try {
      let bannerDataObj = { location: "LOGGED_IN_LANDING_IMAGE" };
      const res = await getBannerByLocation(bannerDataObj);

      if (res && res.data.content) {
        setEventData(res.data.content[0]);
      } else {
        setEventData({});
      }
    } catch (error) {
      setEventData({});
    }
  };
  useEffect(() => {
    getData();
  }, []);

  //API Integration end

  return (
    <div className={classes.container}>
      <MainCarasoul />
      {showbadge && <ProfileCompleteNotice />}

      <div className={classes.eventImgDiv}>
        <img
          src={eventData.bannerPath}
          className={classes.eventImgSrc}
          alt=""
        />

        <div className={classes.eventImgTxt}>
          <Title level={2}>{eventData.title}</Title>
          <p>{eventData.description}</p>
        </div>
      </div>
      <section className={classes.blogsections}>
        <Title level={3}>PriBlogs</Title>
        <BlogCarasoul />
      </section>

      <BannerCarasoul />
    </div>
  );
};

export default LandingPageCmpUser;
