import { Avatar, Button, Col, Empty, Row, Skeleton } from "antd";
import classes from "../ProfileInfoTabs.module.css";
import Title from "antd/es/typography/Title";
import { useEffect, useState } from "react";
import { getCompanyInformation } from "../../../services/MyProfileService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const type = {
  companyName: "Company Name",
  category_id: "Category ID",
  companyLocation: "Company Location",
  companyAddress: "Company Address",
  companyPhone: "Company Phone",
  companyEmail: "Company Email",
  companyWebsite: "Company Website",
  company_timezone_id: "Company Timezone",
  companyLogoURL: "Company Logo URL",
  companyType: "Company Type",
  ceoName: "Ceo Name",
  annualSale: "Annual Sale",
  annualRevenue: "Annual Revenue",
  noOfEmployee: "No Of Employee",
  keyCompetitors: "Key Competitors",
  businessType: "Retailer Business Type",
  noOfLocations: "No of Locations",
};

const companyType = {
  1: "LLC",
  2: "Public",
  3: "Partnership",
  4: "Sole proprietor",
};

const businessType = {
  1: "Discount Chain",
  2: "General-Department Store",
  3: "Grocery",
  4: "Convenient Store",
  5: "Pet Supplies",
  6: "Other",
};

const CompanyInfo = () => {
  const [loading, setLoading] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [infoData, setInfoData] = useState([]);

  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const generalData = useSelector((state) => state.generalData);

  async function fetchData() {
    try {
      setLoading(true);
      const res = await getCompanyInformation({ userId: auth.id });
      if (res?.data) {
        const data = res.data;

        const infoData = Object.entries(data)
          .filter(([name]) => name in type) // Filter to only include keys present in `type`
          .flatMap(([name, value]) => {
            let mappedName = type[name]; // Map the name using the `type` object

            // Handle special cases
            if (name === "categoryId") {
              value = generalData.categories.find(
                (obj) => obj.id === value,
              )?.name;
            } else if (name === "companyTimezoneId") {
              value = generalData.timezone.find(
                (obj) => obj.id === value,
              )?.timezone;
            } else if (name === "companyLogoURL") {
              setLogoUrl(value);
              return []; // Skip this element
            } else if (name === "companyType") {
              value = companyType[value];
            } else if (name === "businessType") {
              value = businessType[value];
            }

            // Only include the item if the value exists
            if (value) {
              return {
                name: mappedName,
                data: value,
              };
            } else {
              return [];
            }
          });

        setInfoData(infoData);
      }
    } catch (err) {
      console.error("Error fetching company information:", err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infoCmp = infoData.map((item, index) => (
    <Col
      xs={24}
      md={24}
      lg={12}
      xl={12}
      xxl={12}
      className={classes["data-item"]}
      key={`list-item-${item.name}-${index}`}
    >
      <Title level={5} className={classes.itemHeading}>
        {item.name}
      </Title>
      <p className={classes.itemData}>{item.data}</p>
    </Col>
  ));

  return loading ? (
    <>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
      <Skeleton></Skeleton>
    </>
  ) : infoData.length !== 0 ? (
    <>
      {logoUrl && <Avatar src={logoUrl} className={classes.avatar} />}
      <Row gutter={12} className={classes["data-container"]}>
        {infoCmp}
      </Row>
    </>
  ) : (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="Your profile is incomplete. Click below button to complete your profile."
    >
      <Button type="primary" onClick={() => navigate("/app/createprofile")}>
        Complete Now
      </Button>
    </Empty>
  );
};

export default CompanyInfo;
