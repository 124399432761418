import React from "react";
import { Col, Row } from "antd";
import classes from "./Footer.module.css";

import logo from "./../../images/Footer/logo.png";
//import fb from "./../../images/Footer/Facebook.png";
//import google from "./../../images/Footer/Google.png";
// import whatsapp from "./../../images/Footer/WhatsApp.png";
import instagram from "./../../images/Footer/Instagram.png";
import mail from "./../../images/Footer/Mail.png";
import twitter from "./../../images/Footer/twitter_x.png";
import linkedin from "./../../images/Footer/linkedin.png";
import tiktok from "./../../images/Footer/tiktok.png";

import { Link } from "react-router-dom";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";

const socialIcons = [
  // { icon: fb, name: "fb-logo", link: "#" },
  // { icon: google, name: "google-logo", link: "#" },
  { icon: mail, name: "mail-logo", link: "#" },
  // { icon: whatsapp, name: "whatsapp-logo", link: "#" },
  {
    icon: twitter,
    name: "twitter-logo",
    link: "https://twitter.com/PRIBR_LLC",
  },
  {
    icon: linkedin,
    name: "linkedin-logo",
    link: "https://www.linkedin.com/company/pribr",
  },
  {
    icon: tiktok,
    name: "tiktok-logo",
    link: "https://www.tiktok.com/@pribr.llc ",
  },
  {
    icon: instagram,
    name: "instagram-logo",
    link: "https://www.instagram.com/pribr.llc/ ",
  },
];

const footerList = [
  {
    //header: "Quick Links",
    items: [
      {
        name: "Home",
        link: "/",
      },
      {
        name: "Dashboard",
        link: "/app/dashboard",
      },
      {
        name: "My Products",
        link: "/app/products",
      },
      {
        name: "Chat",
        link: "/app/chat",
      },
    ],
  },
  // {
  //   header: "Customer Support",
  //   items: [
  //     {
  //       name: "Success Stories",
  //       link: "#",
  //     },
  //     {
  //       name: "Blog",
  //       link: "#",
  //     },
  //     {
  //       name: "In The News",
  //       link: "#",
  //     },
  //     {
  //       name: "Webinars",
  //       link: "#",
  //     },
  //     {
  //       name: "Support",
  //       link: "#",
  //     },
  //   ],
  // },
  {
    // header: "Policies and T&C",
    items: [
      // hide Terms & Conditions and About Us page as per client message on skype group on 26th Dec, 2023
      {
        name: "About Us",
        link: "/public/about",
      },
      {
        name: "Privacy Policy",
        link: "/public/privacy",
      },
      {
        name: "Terms & Conditions",
        link: "/public/terms",
      },
    ],
  },
];

const Footer = () => {
  return (
    <>
      <div className={classes.container}>
        <Row className={[classes.innerContainer, classes.topFooter]}>
          <div className={classes.maxWidth}>
            <Col
              xs={{ offset: 0, span: 24 }}
              md={{ offset: 0, span: 24 }}
              lg={24}
            >
              <Row>
                <Col sm={24} md={24} lg={8}>
                  <Row>
                    <Col sm={24} md={24}>
                      <div
                        className={classes.brandContact}
                        style={{ position: "relative" }}
                      >
                        <img
                          className={classes.logo}
                          src={logo}
                          alt="footer-logo"
                        />
                        <div className={classes.info}>
                          <ul className={classes.list1}>
                            <Link
                              to={"mailto:info@pribr.com"}
                              style={{ color: "white" }}
                            >
                              <li>
                                <MailOutlined style={{ marginRight: "7px" }} />
                                info@pribr.com
                              </li>
                            </Link>
                            <Link
                              to={"mailto:support@pribr.com"}
                              style={{ color: "white" }}
                            >
                              <li style={{ marginTop: "10px" }}>
                                <MailOutlined style={{ marginRight: "7px" }} />
                                support@pribr.com
                              </li>
                            </Link>
                            <Link
                              to={"tel:+17575500730"}
                              style={{ color: "white" }}
                            >
                              <li style={{ marginTop: "10px" }}>
                                <PhoneOutlined /> +1 757 550 0730
                              </li>
                            </Link>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {footerList.map((list, listIndex) => (
                  <Col
                    xs={24}
                    sm={12}
                    lg={8}
                    key={`footer-list-${list.header}-${listIndex}`}
                  >
                    <ul className={classes.list2}>
                      <p className={classes.list2header}>{list.header}</p>
                      {list.items.map((listItem, itemIndex) => (
                        <Link
                          to={listItem.link}
                          key={`list-item-${listItem.name}-${itemIndex}`}
                        >
                          <li className={classes["list-item"]}>
                            {listItem.name}
                          </li>
                        </Link>
                      ))}
                    </ul>
                  </Col>
                ))}
              </Row>
            </Col>
          </div>
        </Row>
        <Row className={classes.bottomFooter}>
          <div className={classes.maxWidth}>
            <Col lg={12} md={24} sm={24}>
              <div className={classes.icons}>
                {socialIcons.map((icons, index) => (
                  <Link
                    to={icons.link}
                    key={`social-icons-${icons.name}-${index}`}
                  >
                    <img
                      className={classes["social-icon"]}
                      src={icons.icon}
                      alt={icons.name}
                    />
                  </Link>
                ))}
              </div>
            </Col>
            <Col lg={12} md={24} sm={24}>
              <div className="justify-content-center align-content-center h-100">
                Copyright &copy; {new Date().getFullYear()} PriBr
              </div>
            </Col>
          </div>
        </Row>
      </div>
    </>
  );
};

export default React.memo(Footer);
