import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import {
  addWindow,
  setChatList,
  updateChatList,
} from "./store/slice/chat_slice";
import { db } from "./services/firebase";
import { RenderRoutes } from "./routes";
import useWindowSize from "./hooks/useWindowSize";

import { getChatListFromSnapShot } from "./utils/chat.helper";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { width } = useWindowSize();

  const token = localStorage.getItem("token");

  // Below useEffect is for chat
  useEffect(() => {
    let unSubscribe = () => {};
    let initialCall = true;
    const id = localStorage.getItem("id");
    const cq = query(collection(db, "chats"), orderBy("updatedAt"));
    const getInitialData = async () => {
      try {
        const snapshot = await getDocs(cq);
        const list = await getChatListFromSnapShot(snapshot);
        dispatch(setChatList(list));
      } catch (err) {
        console.log("err", err);
      }
    };
    const startListenerForData = () => {
      let newChat;
      return onSnapshot(cq, (snapshot) => {
        snapshot.docChanges()?.forEach(async (changes) => {
          try {
            const data = JSON.parse(JSON.stringify(changes.doc.data()));
            if (
              (changes.type === "added" && data.createdAt) ||
              changes.type === "modified"
            ) {
              newChat = { ...data, id: changes.doc.id };
            }
          } catch (err) {
            throw new Error(err.message);
          }
        });
        const currentUser = newChat?.users?.some((u) => String(u.id) === id);
        if (currentUser) dispatch(updateChatList(newChat));
        const currentUserInitChat =
          typeof newChat?.lastMessage === "object" &&
          Object.keys(newChat?.lastMessage)?.length === 0
            ? String(newChat?.lastMessage?.sender) === id
            : true;
        if (initialCall) {
          initialCall = false;
        } else if (
          location.pathname !== "/app/chat" &&
          currentUser &&
          currentUserInitChat
        ) {
          dispatch(addWindow({ windowType: "chat" }));
          if (newChat) {
            dispatch(addWindow({ windowType: "message", activeChat: newChat }));
          }
        }
      });
    };
    if (token) {
      getInitialData();
      if (width > 767) {
        unSubscribe = startListenerForData();
      }
    }
    return () => {
      unSubscribe();
    };
    // eslint-disable-next-line
  }, [token, width]);

  const routes = useMemo(() => <RenderRoutes />, []);

  return routes;
}

export default App;
