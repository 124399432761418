import classes from "./BlogCarasoul.module.css";
import Slider from "react-slick";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { getBannerByLocation } from "../../../services/LandingPageService";
const { Title } = Typography;

const BlogCarousal = () => {
  //API Integration start
  const [carousalItems, setCarousalItems] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: carousalItems.length > 3 ? 3 : carousalItems.length,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: carousalItems.length > 2 ? 2 : carousalItems.length,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: carousalItems.length > 2 ? 2 : carousalItems.length,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: carousalItems.length > 1 ? 1 : carousalItems.length,
        },
      },
    ],
  };

  const getData = async () => {
    try {
      let bannerDataObj = { location: "LOGGED_IN_LANDING_BLOG" };
      const res = await getBannerByLocation(bannerDataObj);

      if (res && res.data.content) {
        setCarousalItems(res.data.content);
      } else {
        setCarousalItems([]);
      }
    } catch (error) {
      setCarousalItems([]);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  //API Integration end

  const carousal = carousalItems.map((item, index) => (
    <div
      className={classes.card}
      key={`banner-carousal-${item.title}-${index}`}
    >
      <img src={item.bannerPath} className={classes.img} alt="banner" />
      <div className={classes.bannerCopy}>
        <Title level={5} className={classes.title}>
          {item.title}
        </Title>
        <p className={classes.content}>{item.description}</p>
        <Link to={item.redirectUrl} target="_blank">
          Read More
          <ArrowRightOutlined style={{ marginLeft: "20px" }} />
        </Link>
      </div>
    </div>
  ));
  return <Slider {...settings}>{carousal}</Slider>;
};

export default BlogCarousal;
