import React, { useState, useEffect } from "react";
import classes from "./Layout.module.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Layout } from "antd";
import { Outlet, useLocation } from "react-router-dom";
import VerificationStrip from "../General/VerificationStrip";

import { useSelector } from "react-redux";
const { Header: AntDHeader, Footer: AntDFooter, Content } = Layout;

const MainLayout = () => {
  const userObj = useSelector((state) => state.user.data);
  const location = useLocation();
  const isVerifyEmailRoute = location.pathname.includes("/public/verify-email");

  const show =
    localStorage.getItem("token") &&
    !isVerifyEmailRoute &&
    userObj?.isVerified !== true;

  const [scrolltopdata, setscrolltopdata] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY < 5) {
        setscrolltopdata(classes.headerTop);
      } else {
        setscrolltopdata(classes.scrolled);
      }
    });
  }, []);

  return (
    <Layout className={classes.mainLayout}>
      <AntDHeader className={`${scrolltopdata}`}>
        <Header />
      </AntDHeader>

      <Content className={classes.content}>
        {show && <VerificationStrip />}
        <Outlet />
      </Content>
      <AntDFooter className={classes.footer}>
        <Footer />
      </AntDFooter>
    </Layout>
  );
};

export default MainLayout;
