import { Suspense, lazy } from "react";
import { Col, Row, Tabs } from "antd";
import classes from "./Settings.module.css";
import Title from "antd/es/typography/Title";

const Subscription = lazy(() => import("./Subscription/Subscription"));
const ChangePassword = lazy(() => import("./ChangePassword/ChangePassword"));

const tabs = [
  {
    key: 1,
    label: "My Subscription",
    children: (
      <Suspense fallback={"Loading..."}>
        <Subscription />
      </Suspense>
    ),
  },
  {
    key: 2,
    label: "Change Password",
    children: (
      <Suspense fallback={"Loading..."}>
        <ChangePassword />
      </Suspense>
    ),
  },
];

const Settings = () => {
  return (
    <Row className={classes.container} justify="center">
      <Col span={22} className={classes["main-background"]}>
        <Title level={2}>Settings</Title>
        <Tabs defaultActiveKey="1" items={tabs} />
      </Col>
    </Row>
  );
};

export default Settings;
