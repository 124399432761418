import React from "react";
import classes from "./Signup.module.css";
import { Col, Row } from "antd";
import SupplierSignup from "./SupplierSignup";
import { Link } from "react-router-dom";
import RegisterFlowLayout from "../Layout/RegisterFlowLayout";

const Signup = () => {
  return (
    <div className={"maxWidth"}>
      <RegisterFlowLayout title={"Sign Up"}>
        <SupplierSignup />

        <Row>
          <Col span={24}>
            <p className={classes.terms}>
              By continuing you agree to our{" "}
              <Link to="/public/terms">Terms of Usage</Link> and{" "}
              <Link to="/public/privacy">Privacy Policy</Link>
            </p>
          </Col>
          <Col span={24}>
            <p className={classes.terms}>
              Existing user? <Link to="/public/signin">Sign In</Link>
            </p>
          </Col>
        </Row>
      </RegisterFlowLayout>
    </div>
  );
};

export default Signup;
