import { Button, Empty, Skeleton, Table } from "antd";
import classes from "./MemberInfoTab.module.css";
import { useEffect, useState } from "react";
import { getMemberInformation } from "../../../services/MyProfileService";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//Plan Details Start
//T1 plan =1, Premium
//T2 plan =2, Standard
//T3 plan =3, Basic (Free)

//Plan Details End
const dataObj = {
  address: { name: "Address", plan: 3 },
  country: { name: "Location", plan: 2 },
  departmentId: { name: "Department", plan: 2 },
  email: { name: "Email", plan: 1 },
  memberTimezoneId: { name: "Timezone", plan: 2 },
  phone: { name: "Phone", plan: 1 },
  title: { name: "Title", plan: 2 },
  website: { name: "Website", plan: 2 },
  isBroker: { name: "Is Broker", plan: 2 },
  name: { name: "Name", plan: 2 },
};

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    render: (text) => <span className={classes.dataheading}>{text}</span>,
  },
  {
    title: "",
    dataIndex: "data",
    key: "data",
    align: "right",
    render: (text) => <span className={classes.dataContent}>{text}</span>,
  },
];

const MemberInfoTab = (props) => {
  const [memberDetails, setMemberDetails] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const generalData = useSelector((state) => state.generalData);
  const params = useParams();
  const navigate = useNavigate();

  async function fetchData() {
    try {
      setLoading(true);
      const res = await getMemberInformation({ userId: params.supplierId });
      if (res && res.data) {
        const data = res.data;

        const infoData = Object.entries(data).flatMap(([name, value]) => {
          const item = dataObj[name];
          let mappedName = item?.name;

          if (name === "memberTimezoneId") {
            value = generalData.timezone.find(
              (obj) => obj.id == value,
            )?.timezone;
          } else if (name === "isBroker") {
            value = value == 1 ? "Yes" : "No";
          } else if (name === "memberLogoURL") {
            return []; // Skip this element
          }

          return {
            name: mappedName,
            data: value,
            show: item?.plan >= props.currentPlan,
          };
        });

        setMemberDetails(infoData);
      }
    } catch (err) {
      // Handle error
    } finally {
      setLoading(false); // Stop loading after data fetch
    }
  }

  useEffect(() => {
    fetchData();
  }, [generalData, props.currentPlan]);

  const SkellotonCtrl = (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );

  const columns1 = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className={classes.dataheading}>{text}</span>,
    },
    {
      title: "",
      dataIndex: "data",
      key: "data",
      align: "right",
      render: () => (
        <span>
          <p>Get Exclusive Access to Premium Content!</p>
          <Button
            type="primary"
            onClick={() => {
              navigate("/app/settings");
            }}
          >
            Subscribe Now
          </Button>
        </span>
      ),
      onCell: (_, index) => {
        if (index === 0) {
          return {
            rowSpan: 100,
          };
        } else {
          return {
            rowSpan: 0,
          };
        }
      },
    },
  ];

  if (loading) {
    return SkellotonCtrl;
  }

  return (
    <>
      {memberDetails.length !== 0 ? (
        <>
          {memberDetails.filter((obj) => obj.show).length > 0 ? (
            <Table
              columns={columns}
              dataSource={memberDetails.filter((obj) => obj.show)}
              pagination={false}
              showHeader={false}
              bordered={false}
            />
          ) : null}
          {memberDetails.filter((obj) => !obj.show).length > 0 ? (
            <Table
              columns={columns1}
              dataSource={memberDetails.filter((obj) => !obj.show)}
              pagination={false}
              showHeader={false}
            />
          ) : null}
        </>
      ) : (
        <Empty description="No Data Found" />
      )}
    </>
  );
};

export default MemberInfoTab;
