import { Col, Row, Steps } from "antd";
import { Suspense, lazy, useCallback, useEffect, useState } from "react";
import classes from "./CreateProfileRetailer.module.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchPercentage } from "../../../store/slice/getProfilePercentage_slice";
import Title from "antd/es/typography/Title";
import useWindowSize from "../../../hooks/useWindowSize";

import CompanyInfoForm from "./Forms/CompanyInfoForm";
const MemberInfoForm = lazy(() => import("./Forms/MemberInfoForm"));
const CategoryAndProductInfoForm = lazy(() =>
  import("./Forms/CategoryProductInfoForm"),
);
const QualityAssuranceInfoForm = lazy(() =>
  import("./Forms/QualityAssuranceInfoForm"),
);
const ArtworkInfoForm = lazy(() => import("./Forms/ArtworkInfoForm"));

const steps = [
  {
    title: "Company Information",
    description: "This is dummy text for sub part",
  },
  {
    title: "Member Information",
    description: "This is dummy text for sub part",
  },
  {
    title: "Sourcing & Shipping Information",
    description: "This is dummy text for sub part",
  },
  {
    title: "Artwork Information",
    description: "This is dummy text for sub part",
  },
  {
    title: "Quality Assurance Information",
    description: "This is dummy text for sub part",
  },
];

const CreateProfileRetailer = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const windowSize = useWindowSize();

  const [allStep, setAllSteps] = useState(steps);
  const [currentStep, setCurrentStep] = useState(1);

  const percentage = useSelector((state) => state.percentage.value);
  const stageCompleted = useSelector(
    (state) => state.percentage.stageCompleted,
  );

  let mainHeadingSteps = "";
  if (location.pathname === "/app/createprofile") {
    mainHeadingSteps = "Create Profile";
  } else {
    mainHeadingSteps = "Edit Profile";
  }

  useEffect(() => {
    dispatch(fetchPercentage());
  }, [dispatch, currentStep]);

  useEffect(() => {
    setAllSteps((steps) => {
      let newSteps = steps;
      newSteps[0].status =
        stageCompleted.CompanyDetails === "true"
          ? "finish"
          : currentStep === 1
          ? "wait"
          : "wait";
      newSteps[1].status =
        stageCompleted.ProfileMember === "true"
          ? "finish"
          : currentStep === 2
          ? "wait"
          : "wait";
      newSteps[2].status =
        stageCompleted.ProfileCategoryAndProduct === "true"
          ? "finish"
          : currentStep === 3
          ? "wait"
          : "wait";
      newSteps[3].status =
        stageCompleted.ProfileArtwork === "true"
          ? "finish"
          : currentStep === 4
          ? "wait"
          : "wait";
      newSteps[4].status =
        stageCompleted.ProfileQA === "true"
          ? "finish"
          : currentStep === 5
          ? "wait"
          : "wait";
      newSteps[0].icon = (
        <div
          style={{
            background: currentStep === 1 ? "#314259" : "#1a76bd",
            width: "70px",
            height: "70px",
            borderRadius: "50%",
            color: "white",
            position: "relative",
            left: "-4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          1
        </div>
      );
      newSteps[1].icon = (
        <div
          style={{
            background: currentStep === 2 ? "#314259" : "#1a76bd",
            width: "70px",
            height: "70px",
            borderRadius: "50%",
            color: "white",
            position: "relative",
            left: "-4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          2
        </div>
      );
      newSteps[2].icon = (
        <div
          style={{
            background: currentStep === 3 ? "#314259" : "#1a76bd",
            width: "70px",
            height: "70px",
            borderRadius: "50%",
            color: "white",
            position: "relative",
            left: "-4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          3
        </div>
      );
      newSteps[3].icon = (
        <div
          style={{
            background: currentStep === 4 ? "#314259" : "#1a76bd",
            width: "70px",
            height: "70px",
            borderRadius: "50%",
            color: "white",
            position: "relative",
            left: "-4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          4
        </div>
      );
      newSteps[4].icon = (
        <div
          style={{
            background: currentStep === 5 ? "#314259" : "#1a76bd",
            width: "70px",
            height: "70px",
            borderRadius: "50%",
            color: "white",
            position: "relative",
            left: "-4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          5
        </div>
      );

      newSteps[0].title = (
        <div
          style={{
            color: "#1a76bd",
          }}
        >
          {newSteps[0].title}
        </div>
      );
      newSteps[1].title = (
        <div
          style={{
            color: "#1a76bd",
          }}
        >
          {newSteps[1].title}
        </div>
      );
      newSteps[2].title = (
        <div
          style={{
            color: "#1a76bd",
          }}
        >
          {newSteps[2].title}
        </div>
      );
      newSteps[3].title = (
        <div
          style={{
            color: "#1a76bd",
          }}
        >
          {newSteps[3].title}
        </div>
      );
      newSteps[4].title = (
        <div
          style={{
            color: "#1a76bd",
          }}
        >
          {newSteps[4].title}
        </div>
      );

      return newSteps;
    });
  }, [stageCompleted, currentStep]);

  const renderForms = useCallback(() => {
    switch (currentStep) {
      case 1:
        return (
          <CompanyInfoForm
            nextStep={() => setCurrentStep(2)}
            percentage={percentage}
          />
        );
      case 2:
        return (
          <Suspense fallback={"Loading..."}>
            <MemberInfoForm
              prevStep={() => setCurrentStep(1)}
              nextStep={() => setCurrentStep(3)}
              percentage={percentage}
            />
          </Suspense>
        );
      case 3:
        return (
          <Suspense fallback={"Loading..."}>
            <CategoryAndProductInfoForm
              prevStep={() => setCurrentStep(2)}
              nextStep={() => setCurrentStep(4)}
              percentage={percentage}
            />
          </Suspense>
        );
      case 4:
        return (
          <Suspense fallback={"Loading..."}>
            <ArtworkInfoForm
              prevStep={() => setCurrentStep(3)}
              nextStep={() => setCurrentStep(5)}
              percentage={percentage}
            />
          </Suspense>
        );
      case 5:
        return (
          <QualityAssuranceInfoForm
            prevStep={() => setCurrentStep(4)}
            percentage={percentage}
          />
        );
      default:
        break;
    }
  }, [currentStep, percentage]);

  return (
    <Row className={classes.container} justify="center">
      <Col
        xs={22}
        sm={22}
        md={22}
        lg={8}
        xl={7}
        xxl={6}
        className={classes["main-background"]}
      >
        <Title level={3}>{mainHeadingSteps}</Title>
        <Steps
          current={currentStep - 1}
          onChange={(s) => setCurrentStep(s + 1)}
          direction={windowSize.width < 992 ? "horizontal" : "vertical"}
          labelPlacement={windowSize.width < 992 ? "vertical" : "horizontal"}
          responsive={false}
          items={allStep}
        />
      </Col>

      <Col
        xs={22}
        sm={22}
        md={22}
        lg={14}
        xl={15}
        xxl={16}
        className={classes["main-background"] + " " + classes["content"]}
      >
        <Row justify="space-between">
          <Col>
            <Title level={3}>{steps[currentStep - 1].title}</Title>
          </Col>
          <Col>
            {percentage !== "100" && (
              <Title level={5} className={classes.percentage}>
                {percentage}% complete
              </Title>
            )}
          </Col>
        </Row>
        {renderForms()}
      </Col>
    </Row>
  );
};
export default CreateProfileRetailer;
