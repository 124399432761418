import { Button } from "antd";
import classes from "./VerificationStrip.module.css";
import { sendVerificationMail } from "../../services/VerifyEmailService";
import { toast } from "react-toastify";
import { InfoCircleTwoTone } from "@ant-design/icons";

const VerificationStrip = () => {
  const sendMail = async () => {
    try {
      const res = await sendVerificationMail();
      if (res && res.success === true) {
        toast.success(res.message);
      } else {
        throw new Error(res.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div className={classes.main}>
      <div className={classes.alert}>
        <InfoCircleTwoTone />
        <p>Please Verify Your Email</p>
      </div>
      <Button
        size="medium"
        type="primary"
        onClick={sendMail}
        className={classes.secondaryBtn}
      >
        Resend Verification Email
      </Button>
    </div>
  );
};

export default VerificationStrip;
