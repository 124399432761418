import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { getBannerByLocation } from "../../../services/LandingPageService";
import classes from "./VerticalCarasoul.module.css";

const VerticalCarasoul = () => {
  const sliderRef = useRef();
  const [currentSlide, seCurrentSlide] = useState(1);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5500,
    pauseOnHover: false,
    vertical: false,
    verticalSwiping: false,
    fade: true,
    arrows: false,
  };

  //API Integration start
  const [carsoulItems, setCarsoulItems] = useState([]);
  const getData = async () => {
    try {
      let bannerDataObj = { location: "GUEST_LANDING_CAROUSEL" };

      const res = await getBannerByLocation(bannerDataObj);

      if (res && res.data.content) {
        setCarsoulItems(res.data.content);
      } else {
        setCarsoulItems([]);
      }
    } catch (error) {
      setCarsoulItems([]);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  //API Integration end

  const onClickGoToSlide = (index) => {
    sliderRef.current.slickGoTo(index - 1);
  };

  const slideNoCmp = carsoulItems.map((item, index) => (
    <p
      onClick={() => onClickGoToSlide(index + 1)}
      className={
        currentSlide === index ? classes["slide-blur"] : classes["slide-no"]
      }
      key={`slide-num-${index}`}
    >
      0{index + 1}
    </p>
  ));

  const Carousel = carsoulItems.map((item, index) => (
    <div className={classes.container} key={`slide-${index}`}>
      <img
        src={item.bannerPath}
        alt="Snow"
        className={classes["carousel-image"]}
      />
      <div className={classes["bottom-left"]}>
        <div className={classes.maxWidth}>
          <div className={classes.leftColumn}>
            <h2 className={classes.heading}>{item.title}</h2>
          </div>
          <div className={classes.rightColumn}>
            <p className={classes.content}>{item.description}</p>
          </div>
        </div>
      </div>
      <div className={classes["top-right"]}>{slideNoCmp}</div>
    </div>
  ));

  function handleBeforeChange(oldIndex, newIndex) {
    seCurrentSlide(newIndex + 1);
  }

  return (
    <Slider
      {...settings}
      ref={sliderRef}
      beforeChange={handleBeforeChange}
      className="vertical-slider"
    >
      {Carousel}
    </Slider>
  );
};

export default VerticalCarasoul;
