import { Button, Empty, message, Skeleton } from "antd";
import classes from "./CompanyInfoTab.module.css";
import { getCompanyInformation } from "../../../services/MyProfileService";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const dataObj = {
  companyName: { name: "Company Name", plan: 3 },
  categoryId: { name: "Category Name", plan: 3 },
  companyLocation: { name: "Company Location", plan: 3 },
  companyAddress: { name: "Company Address", plan: 3 },
  companyPhone: { name: "Company Phone", plan: 2 },
  companyEmail: { name: "Company Email", plan: 2 },
  companyWebsite: { name: "Company Website", plan: 2 },
  companyTimezoneId: { name: "Company Timezone", plan: 3 },
  companyType: { name: "Company Type", plan: 3 },
  ceoName: { name: "Ceo Name", plan: 2 },
  annualSale: { name: "Annual Sale", plan: 2 },
  annualRevenue: { name: "Annual Revenue", plan: 2 },
  noOfEmployee: { name: "No Of Employee", plan: 2 },
  keyCompetitors: { name: "Key Competitors", plan: 3 },
  businessType: { name: "Retailer Business Type", plan: 3 },
  noOfLocations: { name: "No Of Locations", plan: 2 },
};

const companyType = {
  1: "LLC",
  2: "Public",
  3: "Partnership",
  4: "Sole proprietor",
};
const businessType = {
  1: "Discount Chain",
  2: "General-Department Store",
  3: "Grocery",
  4: "Convenient Store",
  5: "Pet Supplies",
  6: "Other",
};

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    render: (text) => <span className={classes.dataheading}>{text}</span>,
  },
  {
    title: "",
    dataIndex: "data",
    key: "data",
    align: "right",
    render: (text) => <span className={classes.dataContent}>{text}</span>,
  },
];

const CompanyInfoTab = (props) => {
  const [companyDetails, setCompanyDetails] = useState([]);
  const generalData = useSelector((state) => state.generalData);
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  async function fetchData() {
    try {
      setLoading(true);
      const res = await getCompanyInformation({ userId: params.supplierId });
      if (res && res.data) {
        const data = res.data;

        const infoData = Object.entries(data)?.flatMap(([name, value]) => {
          const item = dataObj[name];
          let mappedName = item?.name; // Default value for name

          if (name === "categoryId") {
            value = generalData?.categories?.find(
              (obj) => obj.id == value,
            )?.name;
          } else if (name === "companyTimezoneId") {
            value = generalData?.timezone?.find(
              (obj) => obj.id == value,
            )?.timezone;
          } else if (name === "companyType") {
            value = companyType[value];
          } else if (name === "businessType") {
            value = businessType[value];
          } else if (name === "companyLogoURL") {
            return [];
          }

          return {
            name: mappedName,
            data: value,
            show: item?.plan >= props.currentPlan,
          };
        });

        setCompanyDetails(infoData);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      message.error("An error occurred while fetching data.");
      // Handle error
    }
  }

  useEffect(() => {
    fetchData();
  }, [generalData, props.currentPlan]);

  if (loading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  if (companyDetails.length === 0) {
    return <Empty description="No Data Found" />;
  }

  const columns1 = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className={classes.dataheading}>{text}</span>,
    },
    {
      title: "",
      dataIndex: "data",
      key: "data",
      align: "right",
      render: () => (
        <span>
          <p>Get Exclusive Access to Premium Content!</p>
          <Button
            type="primary"
            onClick={() => {
              navigate("/app/settings");
            }}
          >
            Subscribe Now
          </Button>
        </span>
      ),
      onCell: (_, index) => {
        if (index == 0) {
          return {
            rowSpan: 100,
          };
        } else {
          return {
            rowSpan: 0,
          };
        }
      },
    },
  ];

  return (
    <>
      {companyDetails?.filter((obj) => obj.show).length > 0 && (
        <Table
          columns={columns}
          dataSource={companyDetails.filter((obj) => obj.show)}
          pagination={false}
          showHeader={false}
          bordered={false}
        />
      )}
      {companyDetails?.filter((obj) => !obj.show).length > 0 && (
        <Table
          columns={columns1}
          dataSource={companyDetails.filter((obj) => !obj.show)}
          pagination={false}
          showHeader={false}
        />
      )}
    </>
  );
};

export default CompanyInfoTab;
