import { Button, Empty, Skeleton, Table } from "antd";
import { getCategoryAndProductInformation } from "../../../services/MyProfileService";
import classes from "./CategoryProductInfoTab.module.css";

import { DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const handleDownload = (fileUrl) => {
  window.open(fileUrl, "_blank");
};

const avgTimeRequired = {
  1: "30 Days",
  2: "60 Days",
  3: "90 Days",
  4: "120 Days",
};
const minimumOrderObj = {
  1: "Case Pack",
  2: "Units",
};

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    render: (text) => <span className={classes.itemHeading}>{text}</span>,
  },
  {
    title: "",
    dataIndex: "data",
    key: "data",
    align: "right",
    render: (text) => {
      if (Array.isArray(text)) {
        return (
          <>
            {text.map((item, index) =>
              Array.isArray(item) ? (
                <div key={index}>
                  {item.map((innerItem, innerIndex) => (
                    <Button
                      style={{ marginRight: "5px" }}
                      key={innerIndex}
                      onClick={() => handleDownload(innerItem)}
                    >
                      Download <DownloadOutlined />
                    </Button>
                  ))}
                </div>
              ) : (
                <>
                  {item?.name && (
                    <span style={{ marginRight: "8px" }}>{item?.name}</span>
                  )}
                  <Button
                    style={{ marginRight: "5px" }}
                    key={index}
                    onClick={() =>
                      handleDownload(item?.logoPath ? item?.logoPath : item)
                    }
                  >
                    Download <DownloadOutlined />
                  </Button>
                </>
              ),
            )}
          </>
        );
      } else {
        return <span className={classes.itemData}>{text}</span>;
      }
    },
  },
];

const CategoryProductInfoTab = (props) => {
  const [infoData, setInfoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();
  const userObj = useSelector((state) => state.user.data);

  const dataObj = {
    averageTimeRequire: {
      name: "Average Print, Pack, and Ship Time requirement after receiving PO",
      plan: 1,
    },
    edi: {
      name: "EDI (Electronic Data Interchange) Compliance Required",
      plan: 2,
    },
    minimumOrder: {
      name: "Category Minimum Order Quantity Requirement",
      plan: 2,
    },
    pickupDetails: { name: "Pickup Details", plan: 1 },
    pickupPoints: {
      name: userObj.user_type === "RETAILER" ? "Shipping" : "Pickup Points",
      plan: 1,
    },
    pickupRequired: { name: "Pickup Required", plan: 3 },
    workWithAllowance: { name: "Work with allowance program", plan: 2 },
    workWithDeadnet: { name: "Work with deadnet program", plan: 2 },
    otherLink: { name: "Other Link", plan: 3 },
    certificateOfInsurancePath: { name: "Certificate of Insurance", plan: 1 },
    factoryAuditReportPath: { name: "Audit Report", plan: 1 },
    ndapath: { name: "NDA", plan: 1 },
    otherCertificatePath: { name: "Other Certificates", plan: 1 },
    privateBrand: { name: "Private Brand", plan: 1 },
  };

  async function fetchData() {
    try {
      const res = await getCategoryAndProductInformation({
        userId: params.supplierId,
      });
      if (res && res.data) {
        const data = res.data;

        const infoData = Object.entries(data).flatMap(([name, value]) => {
          const item = dataObj[name];
          let mappedName = item?.name;

          // Conditionally update mappedName based on the desired condition
          if (name === "averageTimeRequire") {
            value = avgTimeRequired[value];
          } else if (name === "certificateOfInsurancePath") {
            if (
              value ==
              `${process.env.REACT_APP_S3_URL}/user-profile/certificate-of-insurance/null`
            ) {
              return []; // Skip this element
            }

            value = [value];
            //return [];
          } else if (name === "edi") {
            if (value) {
              value = "Yes";
            } else {
              value = "No";
            }
          } else if (name === "documentAndForumPath") {
            return []; // Skip this element
          } else if (name === "factoryAuditReportPath") {
            if (
              value ==
              `${process.env.REACT_APP_S3_URL}/user-profile/factory-audit-report/null`
            ) {
              return []; // Skip this element
            }
            value = [value];
          } else if (name === "minimumOrder") {
            value = minimumOrderObj[value];
          } else if (name === "ndapath") {
            if (
              value ===
                `${process.env.REACT_APP_S3_URL}/user-profile/NDA/null` ||
              null
            ) {
              return []; // Skip this element
            }

            value = [value];
          } else if (name === "otherCertificatePath") {
            if (
              value ==
              `${process.env.REACT_APP_S3_URL}/user-profile/other-certificate/null`
            ) {
              return []; // Skip this element
            }

            value = [value];
          } else if (name === "pickupRequired") {
            if (value) {
              value = "Yes";
            } else {
              value = "No";
            }
          } else if (name === "workWithAllowance") {
            if (value) {
              value = "Yes";
            } else {
              value = "No";
            }
          } else if (name === "workWithDeadnet") {
            if (value) {
              value = "Yes";
            } else {
              value = "No";
            }
          } else if (name === "privateBrand") {
            if (value.length == 0) {
              return []; // Skip this element
            }
          }

          return {
            name: mappedName,
            data: value,
            show: item?.plan >= props.currentPlan,
          };
        });

        setInfoData(infoData);
      }
    } catch (err) {
      // Handle error
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, [props.currentPlan]);

  const columns1 = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className={classes.dataheading}>{text}</span>,
    },
    {
      title: "",
      dataIndex: "data",
      key: "data",
      align: "right",
      render: () => (
        <span>
          <p>Get Exclusive Access to Premium Content!</p>
          <Button type="primary" onClick={() => navigate("/app/settings")}>
            Subscribe Now
          </Button>
        </span>
      ),
      onCell: (_, index) => {
        if (index === 0) {
          return { rowSpan: 100 };
        } else {
          return { rowSpan: 0 };
        }
      },
    },
  ];

  if (loading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  return infoData?.length > 0 ? (
    <>
      {infoData.filter((obj) => obj.show).length > 0 && (
        <Table
          columns={columns}
          dataSource={infoData.filter((obj) => obj.show)}
          pagination={false}
          showHeader={false}
          bordered={false}
        />
      )}
      {infoData.filter((obj) => !obj.show).length > 0 && (
        <Table
          columns={columns1}
          dataSource={infoData.filter((obj) => !obj.show)}
          pagination={false}
          showHeader={false}
        />
      )}
    </>
  ) : (
    <Empty description="No Data Found" />
  );
};

export default CategoryProductInfoTab;
