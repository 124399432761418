import { DownloadOutlined } from "@ant-design/icons";
import { Button, Empty, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getArtworkInformation } from "../../../services/MyProfileService";
import classes from "./ArtworkInfoTab.module.css";

const handleDownload = (fileUrl) => {
  window.open(fileUrl, "_blank");
};

const artworlLabelObj = {
  1: "Full InHouse label design",
  2: "Through our Ad Agency",
  3: "Basic InHouse capabilities",
  4: "Require vendors/suppliers to provide artwork",
};
const costPrefrenceObj = {
  1: "Retailer covers artwork cost",
  2: "Retailer requires vendors to cover for artwork cost",
  3: "Retailer shares artwork cost",
  4: "Retailer may share some artwork cost, depending on the order",
};

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    render: (text) => <span className={classes.itemHeading}>{text}</span>,
  },
  {
    title: "",
    dataIndex: "data",
    key: "data",
    align: "right",
    render: (text) => {
      if (Array.isArray(text)) {
        return (
          <>
            {text.map((item, index) =>
              Array.isArray(item) ? (
                <div key={index}>
                  {item.map((innerItem, innerIndex) => (
                    <Button
                      style={{ marginRight: "5px" }}
                      key={innerIndex}
                      onClick={() => handleDownload(innerItem)}
                    >
                      Download <DownloadOutlined />
                    </Button>
                  ))}
                </div>
              ) : (
                <Button key={index} onClick={() => handleDownload(item)}>
                  Download <DownloadOutlined />
                </Button>
              ),
            )}
          </>
        );
      } else {
        return <span className={classes.itemData}>{text}</span>;
      }
    },
  },
];

const ArtworkInfoTab = (props) => {
  const [infoData, setInfoData] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state
  const params = useParams();
  const navigate = useNavigate();
  const userObj = useSelector((state) => state.user.data);

  const dataObj = {
    adAgencyName: {
      name: "Agency Name",
      plan: 1,
    },
    artworkContact: {
      name: "Primary contact for Artwork",
      plan: 1,
    },
    artworkEmail: {
      name: "Email",
      plan: 1,
    },
    artworkLabel: { name: "Artwork and Label Design Capabilities", plan: 1 },
    artworkRequirements: {
      name:
        userObj.user_type === "RETAILER"
          ? "Retailer is able to provide artwork requirements"
          : "Supplier is able to provide artwork requirements",
      plan: 1,
    },
    artworkRequirementPath: { name: "Artwork Requirement", plan: 1 },
    dielinesPath: { name: "Dielines", plan: 1 },
    printerSpecsPath: { name: "Printer Specs", plan: 1 },
    artworkCostPreference: { name: "Artwork Cost Preference", plan: 1 },
  };

  async function fetchData() {
    try {
      setLoading(true); // Start loading
      const res = await getArtworkInformation({ userId: params.supplierId });
      if (res && res.data) {
        const data = res.data;

        const infoData = Object.entries(data).flatMap(([name, value]) => {
          const item = dataObj[name];
          let mappedName = item?.name;

          // Conditionally update mappedName based on the desired condition
          if (name === "artworkLabel") {
            value = artworlLabelObj[value];
          } else if (name === "artworkRequirements") {
            value = value ? "Yes" : "No";
          } else if (name === "artworkRequirementPath") {
            if (
              value ===
                `${process.env.REACT_APP_S3_URL}/user-profile/artwork-req/null` ||
              null
            ) {
              return []; // Skip this element
            }
            value = [value];
          } else if (name === "dielinesPath") {
            value = [value];
          } else if (name === "printerSpecsPath") {
            value = [value];
          } else if (name === "productCatalogPath") {
            return []; // Skip this element
          } else if (name === "artworkCostPreference") {
            value = costPrefrenceObj[value];
          }

          return {
            name: mappedName,
            data: value,
            show: item?.plan >= props.currentPlan,
          };
        });

        setInfoData(infoData);
      }
    } catch (err) {
      // Handle error
    } finally {
      setLoading(false); // End loading
    }
  }

  useEffect(() => {
    fetchData();
  }, [props.currentPlan]);

  const SkellotonCtrl = (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );

  const columns1 = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className={classes.dataheading}>{text}</span>,
    },
    {
      title: "",
      dataIndex: "data",
      key: "data",
      align: "right",
      render: () => (
        <span>
          <p>Get Exclusive Access to Premium Content!</p>
          <Button
            type="primary"
            onClick={() => {
              navigate("/app/settings");
            }}
          >
            Subscribe Now
          </Button>
        </span>
      ),
      onCell: (_, index) => {
        if (index === 0) {
          return {
            rowSpan: 100,
          };
        } else {
          return {
            rowSpan: 0,
          };
        }
      },
    },
  ];

  return (
    <>
      {loading ? (
        SkellotonCtrl
      ) : infoData.length !== 0 ? (
        <>
          {infoData.filter((obj) => obj.show).length > 0 ? (
            <Table
              columns={columns}
              dataSource={infoData.filter((obj) => obj.show)}
              pagination={false}
              showHeader={false}
              bordered={false}
            />
          ) : null}
          {infoData.filter((obj) => !obj.show).length > 0 ? (
            <Table
              columns={columns1}
              dataSource={infoData.filter((obj) => !obj.show)}
              pagination={false}
              showHeader={false}
              locale={{ empty: "" }}
            />
          ) : null}
        </>
      ) : (
        <Empty description="No Data Found" />
      )}
    </>
  );
};

export default ArtworkInfoTab;
