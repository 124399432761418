import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import classes from "./MyProfile.module.css";
import CompanyInfoData from "./ProfileInfoTabs/CompanyInfo";
import Title from "antd/es/typography/Title";
import { useSelector } from "react-redux";
import { getProfileComplitionPercentage } from "../../services/CreateProfileService";
import MemberInfoData from "./ProfileInfoTabs/MemberInfo";
import CategoryAndProductInfoData from "./ProfileInfoTabs/CategoryAndProductInfo";
import ArtworkInfoData from "./ProfileInfoTabs/ArtworkInfo";
import QualityAssuranceInfoData from "./ProfileInfoTabs/QualityAssuranceInfo";
import { useNavigate } from "react-router-dom";

const heading = {
  1: "Company Information",
  2: "Member Information",
  3: "Sourcing & Shipping Information",
  4: "Artwork Requirement",
  5: "Quality Assurance Information",
};

const MyProfile = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getProfileComplitionPercentage({ userId: auth.id });
        if (res && res.data) {
          setPercentage(res.data.Percentage);
        }
      } catch (err) {
        /* empty */
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickHandler = (page) => {
    setCurrentStep(page);
  };

  const stepsHeaderDescArr = [
    {
      name: "Company Information",
      description: "",
      handler: () => clickHandler(1),
    },
    {
      name: "Member Information",
      description: "",
      handler: () => clickHandler(2),
    },
    {
      name: "Sourcing & Shipping Information",
      description: "",
      handler: () => clickHandler(3),
    },
    {
      name: "Artwork Information",
      description: "",
      handler: () => clickHandler(4),
    },
    {
      name: "Quality Assurance Information",
      description: "",
      handler: () => clickHandler(5),
    },
  ];

  const StepCmp = stepsHeaderDescArr.map((step, index) => {
    const active = currentStep === index + 1;
    return (
      <div
        className={
          classes["step-outer"] +
          " " +
          (active ? classes["step-outer-active"] : "")
        }
        onClick={step.handler}
        key={index}
      >
        <div
          className={
            classes["step-item"] +
            " " +
            (active ? classes["step-item-active"] : "")
          }
        >
          <Title level={3} className={classes["step-header"]}>
            {step.name}
          </Title>
          <p className={classes["step-description"]}>{step.description}</p>
        </div>
      </div>
    );
  });

  const steps = () => {
    switch (currentStep) {
      case 1:
        return <CompanyInfoData />;
      case 2:
        return <MemberInfoData />;
      case 3:
        return <CategoryAndProductInfoData />;
      case 4:
        return <ArtworkInfoData />;
      case 5:
        return <QualityAssuranceInfoData />;
      default:
        return;
    }
  };

  const editHandler = () => {
    navigate("/app/createprofile");
  };

  return (
    <Row className={classes.container} justify="center">
      <Col
        xs={22}
        sm={22}
        md={22}
        lg={8}
        xl={7}
        xxl={6}
        className={classes["main-background"] + " p-0"}
      >
        <Title level={3} className={classes["my-profile-header"]}>
          My Profile
        </Title>

        {StepCmp}

        {percentage !== "100" && (
          <div className={classes["percentages"]}>
            <div>
              <p className={classes.percentage}>{`${percentage}%`}</p>
              <p className={classes["percentage-text"]}>Profile Completed</p>
            </div>
            <Button
              type="primary"
              htmlType="button"
              className={classes["complete-btn"]}
              onClick={editHandler}
            >
              Complete now!
            </Button>
          </div>
        )}
      </Col>

      <Col
        xs={22}
        sm={22}
        md={22}
        lg={14}
        xl={15}
        xxl={16}
        className={classes["main-background"] + " " + classes["content"]}
      >
        <div className="d-flex justify-content-between">
          <Title level={3} className={classes.heading}>
            {heading[currentStep]}
          </Title>
          <Button
            size="large"
            type="default"
            htmlType="button"
            onClick={editHandler}
            className={classes.editBtn}
          >
            Edit
          </Button>
        </div>
        {steps()}
      </Col>
    </Row>
  );
};

export default MyProfile;
