import { CameraOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Select, Skeleton, Tooltip, Upload } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import FileValidationConstant from "../../../../constant/FileValidationConstant";
import { companyNameSchema } from "../../../../schemas";
import {
  companyInformationRetailer,
  getCompanyInformationRetailer,
} from "../../../../services/CreateProfileService";
import { fetchUser } from "../../../../store/slice/user_slice";
import classes from "./CompanyInfoForm.module.css";

const businessTypeArr = [
  { value: 1, label: "Discount Chain" },
  { value: 2, label: "General-Department Store" },
  { value: 3, label: "Grocery" },
  { value: 4, label: "Convenient Store" },
  { value: 5, label: "Pet Supplies" },
  { value: 6, label: "Other" },
];
// const locationArr = [
//   { value: "location1" },
//   { value: "location2" },
//   { value: "location3" },
//   { value: "location4" },
//   { value: "location5" },
//   { value: "location6" },
// ];
const annualRevenueArr = [
  { value: "< $100K" },
  { value: "$100K - $500K" },
  { value: "$500K - $1M" },
  { value: "$1M - $10M" },
  { value: "$10M - $50M" },
  { value: "$50M - $100M" },
  { value: "$100M - $500M" },
  { value: "$500M - $1B" },
  { value: "$1B - $2B" },
  { value: "$2B - $3B" },
  { value: "> $3B" },
];
const noOfEmployeesArr = [
  { value: "< 50" },
  { value: "50 to 100" },
  { value: "100 to 500" },
  { value: "500 to 1000" },
  { value: "1000 to 5000" },
  { value: "5000 to 10000" },
  { value: "> 10000" },
];
const noOfLocationsArr = [
  { value: "Online" },
  { value: "< 50" },
  { value: "50 to 100" },
  { value: "100 to 500" },
  { value: "500 to 1000" },
  { value: "1000 to 2000" },
  { value: "2000 to 3000" },
  { value: "3000 to 5000" },
  { value: "5000 to 10000" },
  { value: "> 10000" },
];

const CompanyInfoForm = () => {
  const [loading, setLoading] = useState(true);
  const [logoError, setLogoError] = useState({ message: "", exists: false });

  const [form] = Form.useForm();
  const auth = useSelector((state) => state.auth);
  const generalData = useSelector((state) => state.generalData);
  const locationArr = generalData?.location?.map((obj) => {
    return {
      value: obj.name,
      label: obj.name,
    };
  });

  let userPlan = parseInt(localStorage.getItem("subscription"), 10) ?? 1;
  console.log("userPlan: ", userPlan);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState({
    companyLogoURL: "",
    companyName: "",
    businessType: null,
    companyLocation: null,
    companyAddress: "",
    companyWebsite: "",
    ceoName: "",
    //annualSale: "",
    noOfLocations: "",
    annualRevenue: null,
    noOfEmployee: null,
    keyCompetitors: "",
  });
  const [saveData, setSaveData] = useState(false);

  useEffect(() => {
    getCompanyInformationRetailer({ userId: auth.id })
      .then((res) => {
        if (res?.data) {
          const fetchedData = res.data;

          const data = {
            companyName: fetchedData.companyName,
            businessType: fetchedData.businessType,
            companyLocation:
              fetchedData.companyLocation !== "null"
                ? fetchedData.companyLocation
                : null,
            companyAddress: fetchedData.companyAddress,
            companyWebsite: fetchedData.companyWebsite,
            ceoName: fetchedData.ceoName,
            //annualSale: fetchedData.annualSale,
            annualRevenue:
              fetchedData.annualRevenue !== "null"
                ? fetchedData.annualRevenue
                : null,
            keyCompetitors: fetchedData.keyCompetitors,
            noOfEmployee: fetchedData.noOfEmployee,
            noOfLocations: fetchedData.noOfLocations,
            companyLogoURL: fetchedData.companyLogoURL,
          };
          setInitialValues(data);
          setLoading(false);
        } else {
          throw new Error(res.message);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }, [auth.id]);

  const submitHandler = async (values) => {
    if (logoError.exists) {
      return;
    }

    let formData = new FormData();
    values.companyAddress &&
      formData.append("companyAddress", values.companyAddress);
    values.annualRevenue &&
      formData.append("annualRevenue", values.annualRevenue);
    // values.annualSale && formData.append("annualSale", values.annualSale);
    values.businessType && formData.append("businessType", values.businessType);
    values.ceoName && formData.append("ceoName", values.ceoName);
    values.companyName && formData.append("companyName", values.companyName);
    values.keyCompetitors &&
      formData.append("keyCompetitors", values.keyCompetitors);
    values.companyLocation &&
      formData.append("companyLocation", values.companyLocation);
    values.noOfEmployee && formData.append("noOfEmployee", values.noOfEmployee);
    values.noOfLocations &&
      formData.append("noOfLocations", values.noOfLocations);
    values.companyWebsite &&
      formData.append("companyWebsite", values.companyWebsite);
    auth.id && formData.append("userId", auth.id);

    if (values.companyLogoURL?.file) {
      formData.append(
        "companyLogo",
        values.companyLogoURL?.file?.originFileObj,
      );
    }

    setSaveData(true);
    try {
      const res = await companyInformationRetailer(formData);
      if (res?.data) {
        toast.success(res.message);
        dispatch(fetchUser());
      } else {
        throw new Error(res.message);
      }
      setSaveData(false);
    } catch (err) {
      setSaveData(false);
      toast.error(err.message);
    }
  };

  const changeUploadFile = (fileObj) => {
    let file = { file: fileObj?.fileList?.[0] };
    const allowedFileTypeLogo =
      FileValidationConstant.ALLOWED_FILE_TYPE_LOGO_CP_RETAILER_FORM1;
    const allowedFileSizeLogo =
      FileValidationConstant.ALLOWED_FILE_SIZE_LOGO_CP_RETAILER_FORM1;

    const fileExtension = `.${file?.file?.name
      ?.split(".")
      .pop()
      .toLowerCase()}`;
    const isValidFileType = allowedFileTypeLogo.includes(fileExtension);
    const isValidFileSize = file?.file?.size <= allowedFileSizeLogo;

    if (!file.file) {
      form.setFieldValue("companyLogoURL", null);
      setInitialValues((preVal) => ({ ...preVal, companyLogoURL: null }));
      setLogoError({ message: "", exists: false });
      return;
    }

    if (!isValidFileType && file.file) {
      setLogoError({
        message: `Only ${allowedFileTypeLogo.join(", ")} files are allowed.`,
        exists: true,
      });
      return;
    }

    if (!isValidFileSize && file.file) {
      setLogoError({
        message: `File size must be within ${
          allowedFileSizeLogo / (1024 * 1024)
        }MB.`,
        exists: true,
      });
      return;
    }
    setLogoError({ message: ``, exists: false });

    if (file.file) {
      file.file.status = "done";
      setInitialValues((preVal) => ({
        ...preVal,
        companyLogoURL: file.file?.originFileObj,
      }));
    }
  };

  if (loading) {
    return (
      <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </>
    );
  }

  return (
    <Form
      onFinish={submitHandler}
      initialValues={initialValues}
      autoCorrect="off"
    >
      <p className="m-0" style={{ marginBottom: "8px" }}>
        {`Only ${FileValidationConstant.ALLOWED_FILE_TYPE_LOGO_CP_RETAILER_FORM1.join(
          ", ",
        )} file types are supported.`}
      </p>
      <Form.Item
        name="companyLogoURL"
        rules={[{ required: true, message: "Please upload company logo" }]}
      >
        <Upload
          maxCount={1}
          action={null}
          listType="picture-circle"
          showUploadList={false}
          onChange={changeUploadFile}
          accept={FileValidationConstant.ALLOWED_FILE_TYPE_LOGO_CP_RETAILER_FORM1.join(
            ", ",
          )}
        >
          {initialValues.companyLogoURL ? (
            <div className="w-100 h-100" style={{ position: "relative" }}>
              <MinusCircleOutlined
                className={classes["remove-image"]}
                title="Remove Picture"
                onClick={(e) => {
                  e.stopPropagation();
                  changeUploadFile(e);
                }}
              />
              <img
                src={
                  typeof initialValues.companyLogoURL === "object"
                    ? URL.createObjectURL(initialValues.companyLogoURL)
                    : initialValues.companyLogoURL
                }
                alt="img"
                className={classes["company-logo"]}
              />
            </div>
          ) : (
            <div>
              <CameraOutlined style={{ fontSize: "27px" }} />
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
          )}
        </Upload>
      </Form.Item>
      {logoError.exists ? (
        <p className={classes["error-text"] + " mt-0"}>{logoError.message}</p>
      ) : null}

      <label htmlFor="companyName" className={classes["form-label"]}>
        Company Name
      </label>
      <Form.Item name="companyName" rules={companyNameSchema}>
        <Input
          id="companyName"
          placeholder="Enter Company Name"
          className={classes["custom-input"]}
        />
      </Form.Item>

      <label htmlFor="businessType" className={classes["form-label"]}>
        Retailer Business Type
      </label>
      <Form.Item
        name="businessType"
        rules={[
          { required: true, message: "Please Select Retailer Business Type" },
        ]}
      >
        <Select
          id="businessType"
          showSearch
          bordered={false}
          placeholder="Select Retailer Business Type"
          className={classes["custom-input"]}
          options={businessTypeArr}
        />
      </Form.Item>

      <label htmlFor="companyLocation" className={classes["form-label"]}>
        Headquarter Location
      </label>
      <Form.Item name="companyLocation">
        {userPlan == 1 ? (
          <Tooltip title="Please upgrade to a premium plan to unlock this field">
            <Select
              id="companyLocation"
              showSearch
              bordered={false}
              placeholder="Select Headquarter Location"
              options={locationArr}
              className={`${classes["custom-input"]} ${
                userPlan == 1 ? classes["disabled-field"] : ""
              }`}
              disabled={userPlan == 1}
            />
          </Tooltip>
        ) : (
          <Select
            id="companyLocation"
            showSearch
            bordered={false}
            placeholder="Select Headquarter Location"
            options={locationArr}
            className={classes["custom-input"]}
            disabled={userPlan == 1}
          />
        )}
      </Form.Item>

      <label htmlFor="companyAddress" className={classes["form-label"]}>
        Address
      </label>
      <Tooltip
        title={
          userPlan == 1
            ? "Please upgrade to a premium plan to unlock this field"
            : ""
        }
      >
        <Form.Item
          name="companyAddress"
          rules={[{ required: true, message: "Please enter your address" }]}
        >
          <Input
            id="companyAddress"
            placeholder="Enter Address"
            className={`${classes["custom-input"]} ${
              userPlan == 1 ? classes["disabled-field"] : ""
            }`}
            disabled={userPlan == 1}
          />
        </Form.Item>{" "}
      </Tooltip>

      <label htmlFor="companyWebsite" className={classes["form-label"]}>
        Website
      </label>
      <Tooltip
        title={
          userPlan == 1
            ? "Please upgrade to a premium plan to unlock this field"
            : ""
        }
      >
        <Form.Item name="companyWebsite">
          <Input
            id="companyWebsite"
            placeholder="Enter website"
            className={`${classes["custom-input"]} ${
              userPlan == 1 ? classes["disabled-field"] : ""
            }`}
            disabled={userPlan == 1}
          />
        </Form.Item>
      </Tooltip>
      <label htmlFor="ceoName" className={classes["form-label"]}>
        President or CEO Name
      </label>
      <Tooltip
        title={
          userPlan == 1
            ? "Please upgrade to a premium plan to unlock this field"
            : ""
        }
      >
        <Form.Item name="ceoName">
          <Input
            id="ceoName"
            placeholder="Enter President or CEO Name"
            className={`${classes["custom-input"]} ${
              userPlan == 1 ? classes["disabled-field"] : ""
            }`}
            disabled={userPlan == 1}
          />
        </Form.Item>
      </Tooltip>
      {/* <label htmlFor="annualSale" className={classes["form-label"]}>
        Gross Annual Sales for last fiscal year
      </label>
      <Form.Item
        name="annualSale"
        rules={[{ required: true, message: "Please Enter your Annual Sales" }]}
      >
        <Input
          id="annualSale"
          placeholder="Enter Gross Annual Sales for last fiscal year"
          className={classes["custom-input"]}
        />
      </Form.Item> */}

      <label htmlFor="noOfLocations" className={classes["form-label"]}>
        No of Locations
      </label>
      <Form.Item name="noOfLocations">
        {userPlan == 1 ? (
          <Tooltip title="Please upgrade to a premium plan to unlock this field">
            <Select
              id="noOfLocations"
              showSearch
              bordered={false}
              placeholder="Select no of locations"
              className={`${classes["custom-input"]} ${
                userPlan == 1 ? classes["disabled-field"] : ""
              }`}
              disabled={userPlan == 1}
              options={noOfLocationsArr}
            />
          </Tooltip>
        ) : (
          <Select
            id="noOfLocations"
            showSearch
            bordered={false}
            placeholder="Select no of locations"
            className={`${classes["custom-input"]} ${
              userPlan == 1 ? classes["disabled-field"] : ""
            }`}
            disabled={userPlan == 1}
            options={noOfLocationsArr}
          />
        )}
      </Form.Item>

      <label htmlFor="annualRevenue" className={classes["form-label"]}>
        Annual Revenue
      </label>
      <Form.Item name="annualRevenue">
        {userPlan == 1 || userPlan == 2 ? (
          <Tooltip title="Please upgrade to a premium plan to unlock this field">
            <Select
              id="annualRevenue"
              showSearch
              bordered={false}
              placeholder="Select Annual Revenue"
              className={`${classes["custom-input"]} ${
                userPlan == 1 || userPlan == 2 ? classes["disabled-field"] : ""
              }`}
              disabled={userPlan == 1 || userPlan == 2}
              options={annualRevenueArr}
            />
          </Tooltip>
        ) : (
          <Select
            id="annualRevenue"
            showSearch
            bordered={false}
            placeholder="Select Annual Revenue"
            className={`${classes["custom-input"]} ${
              userPlan == 1 || userPlan == 2 ? classes["disabled-field"] : ""
            }`}
            disabled={userPlan == 1 || userPlan == 2}
            options={annualRevenueArr}
          />
        )}
      </Form.Item>

      <label htmlFor="noOfEmployee" className={classes["form-label"]}>
        Number of Employees
      </label>
      <Form.Item name="noOfEmployee">
        {userPlan == 1 || userPlan == 2 ? (
          <Tooltip title="Please upgrade to a premium plan to unlock this field">
            <Select
              id="noOfEmployee"
              showSearch
              bordered={false}
              placeholder="Select Number of Employees"
              className={`${classes["custom-input"]} ${
                userPlan == 1 || userPlan == 2 ? classes["disabled-field"] : ""
              }`}
              disabled={userPlan == 1 || userPlan == 2}
              options={noOfEmployeesArr}
            />
          </Tooltip>
        ) : (
          <Select
            id="noOfEmployee"
            showSearch
            bordered={false}
            placeholder="Select Number of Employees"
            className={`${classes["custom-input"]} ${
              userPlan == 1 || userPlan == 2 ? classes["disabled-field"] : ""
            }`}
            disabled={userPlan == 1 || userPlan == 2}
            options={noOfEmployeesArr}
          />
        )}
      </Form.Item>

      <label htmlFor="keyCompetitors" className={classes["form-label"]}>
        Key competitors
      </label>
      <Tooltip
        title={
          userPlan == 1 || userPlan == 2
            ? "Please upgrade to a premium plan to unlock this field"
            : ""
        }
      >
        <Form.Item name="keyCompetitors">
          <Input.TextArea
            id="keyCompetitors"
            placeholder="Enter Key competitors"
            className={`${classes["custom-input"]} ${
              userPlan == 1 || userPlan == 2 ? classes["disabled-field"] : ""
            }`}
            disabled={userPlan == 1 || userPlan == 2}
            rows={4}
          />
        </Form.Item>
      </Tooltip>
      <Button
        type="primary"
        htmlType="submit"
        className={classes["submit-btn"]}
        loading={saveData}
      >
        Save
      </Button>
      {/* <Button
        type="default"
        htmlType="button"
        className={classes["next-btn"]}
        onClick={nextClickHandler}
      >
        Next
      </Button> */}
    </Form>
  );
};

export default CompanyInfoForm;
