import classes from "./QualityAssuranceInfoTab.module.css";
import { Button, Empty, Skeleton, Table } from "antd";
import { useEffect, useState } from "react";
import { getQualityAssuranceInformation } from "../../../services/MyProfileService";
import { useNavigate, useParams } from "react-router-dom";

//Plan Details Start
//T1 plan =1, Premium
//T2 plan =2, Standard
//T3 plan =3, Basic (Free)

//Plan Details End
const dataObj = {
  qaContact: {
    name: "Primary contact for QA",
    plan: 1,
  },
  qaEmail: {
    name: "Email",
    plan: 1,
  },
  qaLab: {
    name: "Labs you work with",
    plan: 1,
  },
  qaLabName: { name: "Added Lab", plan: 1 },
  sourcingPartners: { name: "Sourcing Partners", plan: 1 },
};

const columns = [
  {
    title: "",
    dataIndex: "name",
    key: "name",
    render: (text) => <span className={classes.dataheading}>{text}</span>,
  },
  {
    title: "",
    dataIndex: "data",
    key: "data",
    align: "right",
    render: (text) => <span className={classes.dataContent}>{text}</span>,
  },
];

const QualityAssuranceInfoTab = (props) => {
  const [infoData, setInfoData] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const params = useParams();
  const navigate = useNavigate();

  async function fetchData() {
    try {
      const res = await getQualityAssuranceInformation({
        userId: params.supplierId,
      });
      if (res && res.data) {
        const data = res.data;

        const infoData = Object.entries(data).flatMap(([name, value]) => {
          const item = dataObj[name];
          let mappedName = item?.name;

          if (name === "qaLab") {
            if (value == 1) {
              value = "BV";
            } else if (value == 2) {
              value = "Intertek";
            }
          }

          return {
            name: mappedName,
            data: value,
            show: item?.plan >= props.currentPlan,
          };
        });

        setInfoData(infoData);
      }
      setLoading(false); // Set loading to false once data is fetched
    } catch (err) {
      setLoading(false); // Set loading to false in case of error
      // Handle error
    }
  }

  useEffect(() => {
    fetchData();
  }, [props.currentPlan]);

  const SkellotonCtrl = (
    <>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </>
  );

  const columns1 = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className={classes.dataheading}>{text}</span>,
    },
    {
      title: "",
      dataIndex: "data",
      key: "data",
      align: "right",
      render: () => (
        <span>
          <p>Get Exclusive Access to Premium Content!</p>
          <Button
            type="primary"
            onClick={() => {
              navigate("/app/settings");
            }}
          >
            Subscribe Now
          </Button>
        </span>
      ),
      onCell: (_, index) => {
        if (index === 0) {
          return {
            rowSpan: 100,
          };
        } else {
          return {
            rowSpan: 0,
          };
        }
      },
    },
  ];

  return loading ? (
    SkellotonCtrl
  ) : infoData.length !== 0 ? (
    <>
      {infoData.filter((obj) => obj.show).length > 0 ? (
        <Table
          columns={columns}
          dataSource={infoData.filter((obj) => obj.show)}
          pagination={false}
          showHeader={false}
          bordered={false}
        />
      ) : null}
      {infoData.filter((obj) => !obj.show).length > 0 ? (
        <Table
          columns={columns1}
          dataSource={infoData.filter((obj) => !obj.show)}
          pagination={false}
          showHeader={false}
          locale={{ empty: "" }}
        />
      ) : null}
    </>
  ) : (
    <Empty description="No Data Found" />
  );
};

export default QualityAssuranceInfoTab;
